export class Currency {
    constructor() {
        this.currencyCode = window.Danfil.currency;
    }

    /**
     * @param {Element} $element
     * @param {Number} value
     * @param {String|null} currency
     */
    setPrice($element, value, currency = null) {
        if ($element === null) {
            return;
        }

        $element.innerHTML = this.format(value, currency);
    }

    format(number, currency) {
        let numberString = number.toString();

        if (typeof currency === 'undefined' || currency === null) {
            currency = this.currencyCode;
        }

        // Thousands comma separate
        numberString = numberString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

        return numberString + ' ' + currency;
    }

    /**
     * @param {Element} $element
     * @returns {number}
     */
    getPriceFromElement($element) {
        if ($element === null) {
            return 0;
        }

        let numberString = $element.textContent;
        numberString = numberString.replace(' ', '');

        // match patterns
        //  - "number" e.g. 123456
        //  - "number.number" e.g. 123456.123456
        numberString = numberString.match(/[0-9]+(\.[0-9]+)?/g);

        if (numberString.length === 0) {
            return 0;
        }

        return parseInt(numberString[0]);
    }
}